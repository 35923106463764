:root {
  --color-main: #1824a2;
  --color-main-dark: #001030;
  --color-lavender: #989fc1; /* It's blueish but has an excellent contrast ratio on main-dark  */

  --color-white: #f4f6f7;
  --color-red: #ec7063;
  --color-blue: #5dade2;
  --color-yellow: #f4d03f;
  --color-green: #52be80;
  --color-orange: #ed8936;
  --color-purple: #805ad5;

  --color-white-dark: #4a4a4a;
  --color-red-dark: #701c13;
  --color-blue-dark: #0e4061;
  --color-yellow-dark: #69560a;
  --color-green-dark: #094021;

  --color-hints: rgb(0, 61, 181);
  --color-strikes: rgb(192, 21, 21);

  --color-gray-light: #aaa;
}

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

@import "./tachyons.css";
@import "./tachyons-overrides.css";
@import "./animations.css";
@import "./popover.css";

@import "../../node_modules/rc-slider/assets/index.css";
@import "../../node_modules/nprogress/nprogress.css";

html,
body {
  font-family: "Inconsolata";
  width: 100%;
  height: 100%;
  color: #f0f0f0;
  background: var(--color-main-dark);
  -webkit-font-smoothing : antialiased;
}

body ::-webkit-scrollbar {
  display: none;
}

.font-regular {
  font-family: "Inconsolata";
}

button {
  background-color: white;
}

#nprogress .bar {
  background: var(--color-yellow) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--color-yellow) !important;
  border-left-color: var(--color-yellow) !important;
}

/**
 * Text colors
 */

.main-dark {
  color: var(--color-main-dark);
}

.lavender {
  color: var(--color-lavender);
}

.bg-lavender {
  background-color: var(--color-lavender);
}

.b--lavender {
  border-color: var(--color-lavender);
}

.txt-red-dark {
  color: color-mod(var(--color-red) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-red) lightness(90%));
}

.txt-blue-dark {
  color: color-mod(var(--color-blue) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-blue) lightness(90%));
}

.txt-yellow-dark {
  color: color-mod(var(--color-yellow) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-yellow) lightness(90%));
}

.txt-white-dark {
  color: color-mod(var(--color-white) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-yellow) lightness(90%));
}

.txt-green-dark {
  color: color-mod(var(--color-green) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-green) lightness(90%));
}

.txt-orange-dark {
  color: color-mod(var(--color-orange) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-orange) lightness(90%));
}

.txt-multicolor-dark {
  color: color-mod(var(--color-white) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-white) lightness(90%));
}

.txt-rainbow-dark {
  color: color-mod(var(--color-white) lightness(15%));
  text-shadow: 0 0 2px color-mod(var(--color-white) lightness(90%));
}

/**
 * Background colors
 */

.bg-main {
  background-color: var(--color-main);
}

.bg-main-dark {
  background: var(--color-main-dark);
}

.bg-cta {
  background-color: var(--color-yellow);
}

.txt-yellow {
  color: var(--color-yellow);
}

.bg-white {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-white) lightness(80%)),
    var(--color-white),
    color-mod(var(--color-white) shade(20%))
  );
}

.bg-red {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-red) lightness(70%)),
    var(--color-red),
    color-mod(var(--color-red) shade(30%))
  );
}

.bg-blue {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-blue) lightness(70%)),
    var(--color-blue),
    color-mod(var(--color-blue) shade(30%))
  );
}

.bg-yellow {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-yellow) lightness(70%)),
    var(--color-yellow),
    color-mod(var(--color-yellow) shade(30%))
  );
}

.bg-green {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-green) lightness(70%)),
    var(--color-green),
    color-mod(var(--color-green) shade(20%))
  );
}

.bg-orange {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-orange) lightness(70%)),
    var(--color-orange),
    color-mod(var(--color-orange) shade(30%))
  );
}

.bg-multicolor {
  background-image: url('/static/multicolor.svg');
  background-size: contain
  /* background: conic-gradient(
    var(--color-white) 0deg 72deg,
    var(--color-blue) 72deg 144deg,
    var(--color-green) 144deg 216deg,
    var(--color-red) 216deg 288deg,
    var(--color-yellow) 288deg 360deg
  ); */
}

.bg-rainbow {
  background-image: radial-gradient(
    circle at bottom left,
    var(--color-white) 0% 20%,
    var(--color-blue) 20% 40%,
    var(--color-green) 40% 60%,
    var(--color-yellow) 60% 80%,
    var(--color-red) 80% 100%
  );

  /* Fallback for browsers not supporting radial gradients */
  background-color: var(--color-purple);
}

.bg-rainbow-circle {
  background-image: radial-gradient(
    circle at bottom left,
    var(--color-white) 0% 28%,
    var(--color-blue) 28% 43%,
    var(--color-green) 43% 57%,
    var(--color-yellow) 57% 72%,
    var(--color-red) 72% 100%
  )
}

.bg-hints {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-hints) lightness(70%)),
    var(--color-hints),
    color-mod(var(--color-hints) shade(20%))
  );
}

.bg-strikes {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-strikes) lightness(70%)),
    var(--color-strikes),
    color-mod(var(--color-strikes) shade(20%))
  );
}

.bg-gray-light {
  background: linear-gradient(
    to bottom right,
    color-mod(var(--color-gray-light) lightness(75%)),
    var(--color-gray-light),
    color-mod(var(--color-gray-light) shade(25%))
  );
}

/**
 * Border colors
 */

.b--white {
  border-color: color-mod(var(--color-white) shade(20%));
}

.b--red {
  border-color: color-mod(var(--color-red) shade(20%));
}

.b--blue {
  border-color: color-mod(var(--color-blue) shade(20%));
}

.b--yellow {
  border-color: color-mod(var(--color-yellow) shade(20%));
}

.b--yellow-light {
  border-color: color-mod(var(--color-yellow) lightness(15%));
}

.b--green {
  border-color: color-mod(var(--color-green) shade(20%));
}

.b--multicolor, .b--rainbow {
  border-color: color-mod(var(--color-white) shade(10%));
}

.b--white-clear {
  border-color: var(--color-white);
}

.b--red-clear {
  border-color: var(--color-red);
}

.b--blue-clear {
  border-color: var(--color-blue);
}

.b--yellow-clear {
  border-color: var(--color-yellow);
}

.b--green-clear {
  border-color: var(--color-green);
}

.b--multicolor-clear, .b--rainbow-clear {
  border-color: var(--color-white);
}

.b--hints {
  border-color: color-mod(var(--color-hints) shade(30%));
}

.b--strikes {
  border-color: color-mod(var(--color-strikes) shade(30%));
}

.b--gray-light {
  border-color: color-mod(var(--color-gray-light) shade(20%));
}

.b--main {
  border-color: var(--color-main);
}

.b--main-dark {
  border-color: var(--color-main-dark);
}

.outline-main-dark {
  text-shadow: -0.5px -0.5px 0 var(--color-main-dark),
    0.5px -0.5px 0 var(--color-main-dark), -0.5px 0.5px 0 var(--color-main-dark),
    0.5px 0.5px 0 var(--color-main-dark);
}

/**
 * Animations
 */

.grow {
  transition: transform 0.1s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
  z-index: 2;
}

/**
 * Shadows
 */

.shadow-light {
  box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.4);
}

/* Safari fix? */
button {
  -webkit-appearance: none;
}

@media screen and (min-width: 1024px) and (min-height: 600px) {
  .game, .Popover {
    zoom: 1.2
  }
}


@media screen and (min-width: 1400px) and (min-height: 800px) {
  .game, .Popover {
    zoom: 1.3
  }
}

