@keyframes FontPulse {
  0% {
    font-size: 100%;
  }

  50% {
    font-size: 120%;
  }

  100% {
    font-size: 100%;
  }
}

@keyframes OpacityPulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
