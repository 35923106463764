/*
 * This adds a few intermdiate Tachyons style classes to handle special cases.
 * Definitely not ideal. We should define our own scales & such using css variables.
 * @see https://github.com/tachyons-css/tachyons-custom
 */

.h1\.25 {
  height: 1.25rem;
}

.w1\.25 {
  width: 1.25rem;
}

.h1\.5 {
  height: 1.5rem;
}

.w1\.5 {
  width: 1.5rem;
}

.h2\.5 {
  height: 3rem;
}

.w2\.5 {
  width: 3rem;
}

.w4\.5 {
  width: 12rem;
}

@media screen and (min-width: 50em) {
  /*
   * Padding - Medium Screens
   */
  .ph6\.5-m {
    padding-left: 10rem; padding-right: 10rem;
  }

  .pr6\.5-m {
    padding-right: 10rem;
  }
}

@media screen and (min-width: 80em) { 
  .ph8-l {
    padding-left: 16rem; padding-right: 16rem;
  }
}

@media screen and (min-width: 60em) {
  .h2\.5-l {
    height: 3rem;
  }

  .w2\.5-l {
    width: 3rem;
  }

  .h2\.5 {
    height: 4rem;
  }

  .w2\.5 {
    width: 4rem;
  }

  .h3\.5-l {
    height: 5rem;
  }

  .w3\.5-l {
    width: 5rem;
  }

  .h4\.5-l {
    height: 12rem;
  }

  .w4\.5-l {
    width: 12rem;
  }
}

/*
 * Typography
 */

.lh-normal {
  line-height: 1;
}

.pre-line {
  white-space: pre-line;
}

.f8 {
  font-size: .6rem;
}

/*
 * Flexbox
 */
.flex-square {
  flex: 1 0 auto;
}

.flex-1 {
  flex: 1
}

.flex-square:before {
  content: "";
  padding-top: 100%;
}

/*
 * Sizes
 */
.mh-30vh {
  max-height: 30vh;
}

.top--1\.5 {
  top: -1.5rem
}

/*
 * Borders
 */

.bw0\.5 {
  border-width: 0.075rem;
}

.bw1\.5 { border-width: 0.15rem; }
